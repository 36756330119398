import { APIPath } from '../constants';
import { stringifyQuery } from '../functions';
/** Client for v0 APIs */
export class TenantClient {
    constructor(client) {
        this.client = client;
    }
    getSettings() {
        return this.client.get(this.url(APIPath.Settings), { anonymous: true });
    }
    getAllowedFileTypes() {
        return this.client.get(this.url(APIPath.Settings, APIPath.SettingsAllowedFileTypes));
    }
    getProjects() {
        return this.client.get(this.url(APIPath.Projects));
    }
    getProject(id) {
        return this.client.get(this.url(APIPath.Projects, id));
    }
    getOIDCState(returnUrl, config) {
        return this.client.post(this.url(APIPath.Oidc, APIPath.OidcStates), { body: { returnUrl, ...config }, anonymous: true });
    }
    getGeoRegions(projectId) {
        return this.client.get(this.url(APIPath.Projects, projectId, APIPath.GeoRegions));
    }
    getBeaconRegions(projectId) {
        return this.client.get(this.url(APIPath.Projects, projectId, APIPath.BeaconRegions));
    }
    getAsset(id) {
        return this.client.get(this.url(APIPath.Assets, id));
    }
    queryAsset(query) {
        return this.client.get(this.url(APIPath.Assets), { query: stringifyQuery(query) });
    }
    getRoles(options) {
        return this.client.get(this.url(APIPath.Roles), options);
    }
    /**
     * @deprecated 14/01/2022
     * use user-claims or company-claims endpoints
     */
    getDefaultClaims() {
        console.warn('Deprecated: use user-claims or company-claims endpoints');
        return this.client.get(this.url(APIPath.DefaultClaims));
    }
    getUserClaims() {
        return this.client.get(this.url(APIPath.UserClaims));
    }
    getUserClaim(claimId) {
        return this.client.get(this.url(APIPath.UserClaims, claimId));
    }
    getCompanyClaims() {
        return this.client.get(this.url(APIPath.CompanyClaims));
    }
    getCompanyClaim(claimId) {
        return this.client.get(this.url(APIPath.CompanyClaims, claimId));
    }
    url(...extra) {
        return this.client.buildUrl(...extra);
    }
}
