export var QueryOperators;
(function (QueryOperators) {
    // Combinator
    QueryOperators["And"] = "and";
    QueryOperators["Or"] = "or";
    QueryOperators["Not"] = "not";
    // Presence
    QueryOperators["Has"] = "has";
    QueryOperators["Hasnt"] = "hasnt";
    // Comparison
    QueryOperators["Equal"] = "eq";
    QueryOperators["NotEqual"] = "ne";
    QueryOperators["LowerThan"] = "lt";
    QueryOperators["LowerEqual"] = "le";
    QueryOperators["GreaterThan"] = "gt";
    QueryOperators["GreaterEqual"] = "ge";
    QueryOperators["In"] = "in";
    QueryOperators["Contains"] = "contains";
    QueryOperators["Descendants"] = "descs";
    // Search
    QueryOperators["Search"] = "q";
    QueryOperators["Sort"] = "sort";
    // Pargination
    QueryOperators["Offset"] = "offset";
    QueryOperators["Limit"] = "limit";
    // Include (define response subset of attributes)
    QueryOperators["Include"] = "include";
})(QueryOperators || (QueryOperators = {}));
export var SortDirections;
(function (SortDirections) {
    SortDirections["Ascending"] = "asc";
    SortDirections["Descending"] = "desc";
})(SortDirections || (SortDirections = {}));
