import { APIPath } from '../constants';
/** Client for v0/units APIs */
export class HierarchyClient {
    constructor(client) {
        this.client = client;
    }
    getUnit(id, options) {
        return this.client.get(this.url(id), options);
    }
    getUnits(ids, options) {
        const paramsWithIds = { ...options === null || options === void 0 ? void 0 : options.params, ids: ids.join(',') };
        return this.client.get(this.url(), { ...options, params: paramsWithIds });
    }
    search(params, options) {
        return this.client.get(this.url(), { params: params, ...options });
    }
    getUnitChildren(id, options) {
        return this.client.get(this.url(id, APIPath.HierarchyChildren), options);
    }
    url(...extra) {
        return this.client.buildUrl(APIPath.HierarchyUnits, ...extra);
    }
}
