export var HierarchyUnitState;
(function (HierarchyUnitState) {
    HierarchyUnitState["Active"] = "Active";
    HierarchyUnitState["Inactive"] = "Inactive";
})(HierarchyUnitState || (HierarchyUnitState = {}));
export var FormStyle;
(function (FormStyle) {
    FormStyle["FullForm"] = "fullForm";
    FormStyle["Summary"] = "summary";
})(FormStyle || (FormStyle = {}));
