export var UserStatus;
(function (UserStatus) {
    UserStatus["Pending"] = "Pending";
    UserStatus["Active"] = "Active";
    UserStatus["Inactive"] = "Inactive";
})(UserStatus || (UserStatus = {}));
export var AuthenticationSource;
(function (AuthenticationSource) {
    AuthenticationSource["Internal"] = "Internal";
    AuthenticationSource["External"] = "External";
})(AuthenticationSource || (AuthenticationSource = {}));
export var AuthProvider;
(function (AuthProvider) {
    AuthProvider["Azure"] = "Azure";
    AuthProvider["Auth0"] = "Auth0";
    AuthProvider["Okta"] = "Okta";
})(AuthProvider || (AuthProvider = {}));
export var CompanyStatus;
(function (CompanyStatus) {
    CompanyStatus["Pending"] = "Pending";
    CompanyStatus["Active"] = "Active";
    CompanyStatus["Inactive"] = "Inactive";
})(CompanyStatus || (CompanyStatus = {}));
export var HeaderKeys;
(function (HeaderKeys) {
    HeaderKeys["Authorization"] = "Authorization";
    HeaderKeys["ContentType"] = "Content-Type";
    HeaderKeys["AcceptLanguage"] = "Accept-Language";
    HeaderKeys["Etag"] = "etag";
    HeaderKeys["IfMatch"] = "If-Match";
    HeaderKeys["XUnifiiApp"] = "X-Unifii-App";
    HeaderKeys["XUnifiiDate"] = "X-Unifii-Date";
    HeaderKeys["XUnifiiDevice"] = "X-Unifii-Device";
    HeaderKeys["XUnifiiAnalytics"] = "X-Unifii-Analytics";
    HeaderKeys["XUnifiiContentVersion"] = "X-Unifii-Content-Version";
})(HeaderKeys || (HeaderKeys = {}));
