import { UserStatus } from '../client-types';
export const getUserStatus = ({ isExternal, isActive, hasPassword }) => {
    if (!isExternal && !hasPassword) {
        return UserStatus.Pending;
    }
    return isActive ? UserStatus.Active : UserStatus.Inactive;
};
export const getUserInfoFlags = (status) => {
    switch (status) {
        case UserStatus.Active:
            return { isActive: true };
        case UserStatus.Inactive:
            return { isActive: false, hasPassword: true };
        case UserStatus.Pending:
            return { hasPassword: false, isActive: false, isExternal: false };
    }
};
