import { APIPath } from '../constants';
import { stringifyQuery } from '../functions';
/** Client for v0/companies APIs */
export class CompaniesClient {
    constructor(client) {
        this.client = client;
    }
    query(query, options) {
        return this.client.get(this.url(), { ...options, query: stringifyQuery(query) });
    }
    get(id, options) {
        return this.client.get(this.url(id), options);
    }
    save(company, options) {
        if (!company.id) {
            // Add
            return this.client.post(this.url(), { ...options, body: company });
        }
        // Update
        return this.client.put(this.url(company.id), company, options);
    }
    url(...extra) {
        return this.client.buildUrl(APIPath.Companies, ...extra);
    }
}
