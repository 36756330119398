export var CellTemplateType;
(function (CellTemplateType) {
    CellTemplateType["Lozenge"] = "lozenge";
    CellTemplateType["Icon"] = "icon";
    CellTemplateType["Image"] = "image";
})(CellTemplateType || (CellTemplateType = {}));
export var MessageColour;
(function (MessageColour) {
    MessageColour["Accent"] = "accent";
    MessageColour["Info"] = "info";
    MessageColour["Warning"] = "warning";
    MessageColour["Error"] = "error";
    MessageColour["Success"] = "success";
})(MessageColour || (MessageColour = {}));
